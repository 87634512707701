export const titles = {

  // public routes

  '/login': 'Login',
  '/registration': 'Registration',
  '/terms-and-conditions': 'Terms and Conditions',
  '/privacy-policy': 'Privacy Policy',
  '/saml-login': 'Saml Login',
  '/pwa-login': ' Pwa login',
  '/aes-login': 'Aes Login',
  '/icicilombard/login': 'ICICI Lombard Login',
  '/icicilombard/registration': 'ICICI Lombard Registration',
  '/icicilombard': 'ICICI Lombard Identity Verification',
  '/icicilombard-terms-and-conditions': 'ICICI Lombard Terms and Conditions',
  '/mediassist-login': 'Mediassist Login',
  '/isos-terms': 'Isos Terms and Conditions',

  // private routes

  '/': 'Dashboard',
  '/dashboard': 'Dashboard',
  '/profile': 'Profile',
  '/wallet': 'Wallet',
  '/notifications': 'Notifications',
  '/articles': 'Articles',
  '/improve/events': 'Events',
  '/health-risk-assessment': 'Health Risk Assessment',
  '/dashboard/:id(\\d+)': 'Marketing Tiles',
  '/dashboard/ongoing-activities/:card_type/:id(\\d+)': 'Ongoing Activity',
  '/dashboard/ongoing-activities/:card_type/:id(\\d+)/consultation': 'Ongoing Activity For Consultation',
  '/health-risk-assessment/insights': 'Health Risk Assessment Insights',
  '/health-risk-assessment/getting-started': 'Health Risk Assessment Getting Started',
  '/health-risk-assessment/steps-to-improve': 'Health Risk Assessment Steps To Improve',
  '/personal-health-profile': 'Personal Health Profile',
  '/profile/edit': 'Profile Edit',
  '/trends/': 'Trends',
  '/benefits/gyms': 'Gyms and Fitness',
  '/benefits/gyms/slots': 'Gyms Slots',
  '/trends/:customerToken': 'Customer Trends',
  '/booking-history': 'Booking History',
  '/benefits/reimbursements': 'Reimbursements',
  '/account-details': 'Account Details',
  '/account-details/verify': 'Verify Account Details',
  '/benefits/pharmacy': 'Pharmacy',
  '/new-benefits': 'Health Benefits',
  '/new-benefits/:type': 'Health Benefits',
  '/new-benefits/ahc/:id': 'Annual Health Checkups',
  '/new-benefits/ahc/cart': 'Annual Health Checkups',
  '/new-benefits/ahc/address': 'Annual Health Checkups',
  '/new-benefits/ahc': 'Annual Health Checkups',
  '/new-benefits/vision': 'Vision Checkups',
  '/new-benefits/fertility_treatment': 'Fertility Treatment',
  '/new-benefits/dental': 'Dental Checkups',
  '/new-benefits/covid_vaccination': 'Covid Vaccination',
  '/new-benefits/flu_vaccination': 'Flu Vaccination',
  '/annual-health-checkups': 'Annual Health Checkups',
  '/benefits/vision-checks': 'Vision Checkups',
  '/benefits/dental-checks': 'Dental Checkups',
  '/benefits/covid_vaccination': 'Covid Vaccination',
  '/benefits/flu_vaccination': 'Flu Vaccination',
  '/benefits/vaccination': 'Vaccination',
  '/benefits/spc': 'Specialist Consultation',
  '/benefits/ambulance': 'Ambulance On Demand',
  '/benefits/inclinic': 'Inclinic Consultation',
  '/benefits/cashless/inclinic': 'Cashless Inclinic Consultation',
  '/normal-health-checkups': 'Normal Health Checkups',
  '/lab-tests': 'Lab Tests',
  '/lab-tests/booking': 'Lab Tests Booking',
  '/lab-tests/search': 'Lab Tests Search',
  '/benefits/nhc': 'Normal Health Checkups',
  '/improve/challenges': 'Challenges',
  '/dental-checkups': 'Dental Checkups',
  '/dental-checkups/family': 'Dental Checkups For Family',
  '/vision-checkups': 'Vision Checkups',
  '/vision-checkups/family': 'Vision Checkups For Family',
  '/documents': 'Documents',
  '/documents/:document_id(\\d+)': 'Documents',
  '/medical-history': 'Medical History',
  '/benefits/vaccinations': 'Vaccinations',
  '/benefits/vaccinations/family': 'Vaccinations For Family',
  '/improve': 'Improve',
  '/mobile/account': 'Account Details',
  '/improve/challenges/:id(\\d+)/info': 'Challenge Information',
  '/improve/challenges/:customer_challenge_id(\\d+)': 'Customer Challenge',
  '/improve/challenges/leaderboard': 'Challenges Leaderboard',
  '/leaderboard': 'Leaderboard',
  '/teams/leaderboard': 'Teams Leaderboard',
  '/improve/challenges/leaderboard/teams/create': 'Create Team',
  '/improve/challenges/leaderboard/teams/:team_id(\\d+)/details': 'Team Details',
  '/improve/challenges/:customer_challenge_id(\\d+)/invitations': 'Challenge Invitations',
  '/improve/challenges/:customer_challenge_id(\\d+)/invitations/:invitation_id(\\d+)': 'Challenge Invitation',
  '/improve/challenges/teams': 'Challenges Teams',
  '/teams': 'Teams',
  '/teams/:team_id(\\d+)': 'Teams Details',
  '/myteam': 'My Team',
  '/prescription-upload': 'Prescription Upload',
  '/lab-tests/categories': 'Lab Tests Categories',
  '/order-medicines': 'Order Medicines',
  '/wearables': 'Wearables',
  '/wearables-new': 'Add Wearables',
  '/wearables/new': 'Add Wearable',
  '/settings': 'Settings',
  '/wearables/callback': 'Wearables Callback',
  '/wearables/callback-new': 'Wearables Callback',
  '/immunizations': 'Immunizations',
  '/events': 'Events',
  '/events/:eventId': 'Event Details',
  '/add-family-members': 'Add Family Members',
  '/add-new-family-members': 'Add New Family Members',
  '/family-members': 'Family Members',
  '/medications': 'Medications',
  '/medications/:prescription_id(\\d+)': 'Medications',
  '/announcements': 'Announcements',
  '/articles/:id(\\d+)': 'Article Details',
  '/family-doctor/getting-started': 'Family Doctor Getting Started',
  '/benefits/consultation24x7/call': 'Consultation 24 by 7',
  '/benefits/consultation24x7': 'Consultation 24 by 7',
  '/family-doctor/chat': 'Family Doctor Chat',
  '/consultations': 'Consultation 24 by 7',
  '/appointments/:id(\\d+)': 'Appointment Details',
  '/health-checks/:id(\\d+)': 'Health Check Appointment Details',
  '/gyms/:id(\\d+)': 'Gym Appointment Details',
  '/health-checks': 'Health Checks',
  '/appointments': 'Appointments',
  '/health': 'Health',
  '/order-details': 'Pharmacy Order Details',
  '/order-track': 'Pharmacy Track',
  '/pharmacy-tracking': 'Pharmacy Tracking',
  '/medical-reports': 'Medical Reports',
  '/consultations-history': 'Consultations History',
  '/consultations-history/:consultation_id(\\d+)': 'Consultations History Details',
  '/diet-plans': 'Diet Plans',
  '/fitness-plans': 'Fitness Plans',
  '/invoices': 'Invoices',
  '/support': 'Help & Support',
  '/invoice/:invoice_id(\\d+)': 'Invoice Details',
  '/stress-questionnaire': 'Stress Questionnaire',
  '/covid-19': 'Covid Checkups',
  '/employee-wellbeing': 'Employee Wellbeing',
  '/ewap-consultation': 'Mental wellness Consultation',
  '/ewap-appointments': 'Mental wellness Appointments',
  '/ewap-appointments/:id(\\d+)': 'Mental wellness Appointment Details',
  '/health-coach/:id(\\d+)': 'Health Coach Program Details',
  '/health-coach/payment': 'Health Coach Payment',
  '/benefits': 'Benefits',
  '/benefit-plans': 'Benefit Plans',
  '/benefits/health_checks': 'Health Check Benefits',
  '/benefits/consultations': 'Consultation Benefits',
  '/benefits/wallet_sponsored_benefits': 'Wallet Sponsored Benefits',
  '/benefits/sponsored_benefits': 'Sponsored Benefits',
  '/medical-reports/:assessment_id(\\d+)': 'Medical Report Details',
  '/appointments/:appointment_id(\\d+)/reschedule': 'Reschedule Appointment',
  '/rewards': 'Rewards',
  '/value_plus_savings': 'Value Plus Savings',
  '/rewards/:type': 'Rewards',
  '/my-documents': 'My Documents',
  '/benefits/pharmacy/product': 'Pharmacy',
  '/benefits/pharmacy/cart': 'Pharmacy Cart',
  '/benefits/:benefit_type/:product_category/:category_id(\\d+)': 'Pharmacy Category',
  '/benefits/:benefit_type/:prescription_slug': 'Pharmacy With Prescription',
  '/history': 'History',
  '/benefits/ewap': 'Mental wellness',
  '/consentisos': 'Consent Isos',
  '/feedback': 'Feedback',
  '/health-coach/:enrollment_id(\\d+)/timeline': 'Health Coach Program',
  '/health-coach-article': 'Health Coach Article',
  '/form': 'Form',
  '/d': 'Dashboard',
  '/m/:assessment_id(\\d+)': 'Medical Reports',
  '/a/:appointment_id(\\d+)/reschedule': 'Reschedule Appointment',
  '/c/:consultation_id(\\d+)': 'Consultations History Details',
  '/e/:prescription_id(\\d+)': 'Medications',
  '/h': 'Improve Challenges',
  '/h/:id(\\d+)/info': 'Improve Challenge View',
  '/v': 'Events',
  '/v/:eventId': 'Event Details',
  '/ahc': 'Annual Health Checkups',
  '/in/:invoice_id(\\d+)': 'View Invoice',
  '/h/:customer_challenge_id(\\d+)/in': 'Improve Challenge Invitations',
  '/h/:customer_challenge_id(\\d+)/in/:invitation_id(\\d+)': 'Improve Challenge Invitation',
  '/o/:document_id(\\d+)': 'Document',
  '/feedback/:type/:id(\\d+)/:rating(\\d+)': 'Feedback Rating',
  '/benefits/reimbursements/:id(\\d+)': 'Reimbursement',
  '/healthbuddy': 'Health buddy',
  '/group_health_insurance': 'Group Health Insurance',
  '/value_plus': 'Value Plus',
  '/cashless-benefits-page': 'Cashless Benefits Page',
  '/health-feed': 'Health feed',
};