import request from "../utils/request";
import {
  API_ARTICLES, API_USER_LOGIN, API_FETCH_FAMILY_MEMBERS,
  API_FETCH_SPONSOR_PACKAGE_DETAILS, API_FETCH_PROVIDERS, API_CREATE_APPOINTMENT_REQUEST,
  API_FETCH_SEARCHED_PACKAGES, API_UPLOAD_PRESCRIPTIONS,
  API_DOWNLOAD_PRESCRIPTION, API_FETCH_ADDRESSES,
  API_CREARE_ADDRESS, API_UPDATE_ADDRESS, API_DELETE_ADDRESS,
  API_FETCH_HEALTH_COACH_PROGRAMS, API_FETCH_HEALTH_COACH_PROGRAM, API_HEALTH_COACH_REQUEST,
  API_PROGRAM_ENROLLMENT, API_PROGRAM_ENROLLMENT_PAYMENT_OPTION, API_PROGRAM_ENROLLMENT_POST_PAY,
  API_HEALTH_COACH_PROGRAM_ENROLLMENT,
  API_HEALTH_COACH_PROGRAM_ENROLLMENT_POST_PAY, API_HEALTH_COACH_PROGRAM_ENROLLMENT_PAYMENT_OPTION,
  API_FETCH_HEALTH_COACH_SLOTS, API_FETCH_HEALTH_COACH_FAMILY_MEMBERS, API_CUSTOMER_WEARABLES,
  API_CUSTOMER_WEARABLE_CREATE_TOKEN, API_CUSTOMERS_WEARABLES_SYNCHRONIZING,
  API_CUSTOMERS_WEARABLES_WHEN_TO_SYNC, API_CUSTOMERS_WELLNESS_REPORT, API_WELLNESS_TRENDS, API_BMI_SCORE_TRENDS,
  API_CUSTOMERS_WEARABLES_GARMIN_URL, API_CUSTOMER_WIZARD, API_ADD_FAMILY_MEMBER,
  API_SHARE_LOGIN_INSTRUCTIONS, API_FETCH_HEALTH_PROFILE_DATA, API_FETCH_TRENDS_DATA, API_EWAP,
  API_REGISTRATIONS_VERIFY_EMAIL, API_REGISTRATIONS_VERIFY_COMPANY_CODE, API_REGISTRATIONS_REGISTER_EMPLOYEE,
  API_REGISTRATIONS_SEND_OTP, API_REGISTRATION_VERIFY_MOBILE_NUMBER, API_REGISTRATION_SET_PASSWORD, API_REGISTRATION_OTP_LOGIN, API_FETCH_CUSTOMER_PROFILE, API_FETCH_BOOKING_HISTORY,
  API_FETCH_MEMBER_INFO, API_CUSTOMERS_UPDATE, API_SYNC_STEPS, API_UPDATE_MOBILE_NIUMBER, API_VERIFY_OTP,
  API_APPOINTMENT_FEEDBACK_SUBMIT, API_FETCH_ONGOING_ACTIVITIES, API_FETCH_APPOINTMENT_SLOTS,
  API_RESCHEDULE_APPOINTMENT, API_VOUCHER_DOWNLOAD, API_FETCH_SERVICES, API_FETCH_TALK_WITH_DOC_DATA,
  API_DOCUMENTS_CREATE, API_CUSTOM_TILES_GET, API_ARTICLE_DETAILS_GET,
  API_SAML_AUTHENTICATE, API_VERIFY_SELF_REGISTER_OTP, API_USER_LOGOUT, API_AES_AUTHENTICATE,
  API_FETCH_ALL_PRESCRIPTIONS, API_FETCH_PHARMACY_ORDER_DETAILS, API_MOVE_PRESCRIPTIONS,
  API_FETCH_PHARMACY_TRACKING, API_FETCH_PHARMACY_ORDER_CANCEL, API_FETCH_FEEDBACK, API_FEEDBACK_SUBMIT,
  API_FETCH_MEDICAL_HISTORY, API_DELETE_ASSESSMENT, API_DOWNLOAD_DOCUMENT, API_FETCH_CONSULTATIONS_HISTORY,
  API_FETCH_INVOICES, API_DOWNLOAD_INVOICE, API_DOCTOR_DOCUMENTS, API_VALIDATE_COUPON, API_ENABLE_WHATSAPP,
  API_GET_APPOINTMENT_DETAILS, API_STRESS_QUESTIONS, API_SUBMIT_STRESS_RESPONSE, API_GET_SUMMARY_DETAILS,
  API_FETCH_EWAP_TOKEN, API_VALIDATE_PINCODE,
  API_HEALTH_CHECKS_PAYMENT_OPTIONS, API_GYMS_ORDER_SUMMARY, API_FETCH_FEEDBACK_OPTIONS, API_VERIFY_EMAIL,
  API_VERIFY_EMAIL_OTP, API_UPLOAD_VERIFIABLE_DOCUMENT,
  API_STORE_IDENTITY, API_UPDATE_COVIN_REG_STATUS, API_NEW_PROVIDER_REQUEST, AUTOCOMPLETE_SEARCH, SEARCH_MEDICINE,
  FETCH_PRODUCT_DETAILS, FETCH_BY_CATEGORY, API_PHARMACY_TRANSACTIONS,
  API_OVERRIDE_MOBILE_NUMBER, API_DEACTIVATE_FAMILY_MEMBER,
  API_FETCH_ELIGIBLE_MEMBERS_FOR_SEARCHED_PACKAGE, WALLET_TRANSACTION_HISTORY, WALLET_TRANSACTION_DETAIL,
  API_FETCH_PHARMACY_ORDER_HISTORY_DETAILS, API_FETCH_GYM_BOOKING_HISTORY, API_RECORD_MEMBER_VACCINATION_DETAILS,
  API_MARKETING_TILES, API_COUPON_OFFER, API_GET_DYNAMIC_FORM_QUESTIONS, API_POST_DYNAMIC_FORM_QUESTIONS_RESPONSE, API_UPLOAD_FORM_RESPONSE, API_DELETE_UPLOAD_FORM_RESPONSE,
  API_UPDATE_FAMILY_MEMBER_RELATION, API_AVAILABLE_VACCINES_DOSE,
  API_FORM_POST_PAYMENT_RESPONSE, API_BENEFITS_MENTAL_WELLNESS, API_NEW_DASHBOARD, API_EWAP_DATE_SLOTS, API_EWAP_TIME_SLOTS, API_UPLOAD_EWAP_APPIONTMENTS, API_EWAP_POST_PAYMENT,
  EWAP_APPOINTMENT_CANCEL_REQUEST, API_EWAP_RESCHEDULE_DATE_SLOTS, API_EWAP_RESCHEDULE_TIME_SLOTS, API_UPLOAD_RESCHEDULE_EWAP_APPIONTMENTS, UPDATE_CART, FETCH_CART_DETAILS, API_FETCH_AHC_FAMILY_MEMBERS, API_FETCH_AHC_PACKAGES, API_GROUP_HEALTH_INSURANCE, API_FETCH_SLOTS, API_CANCEL_BENEFITS_APPOINTMENT, API_BENEFIT_REIMBURSEMENT, API_AMBULANCE_ON_DEMAND, MEDIA_ASSIST_AUTH, API_SPENDING_ACCOUNT, API_KNOW_MORE_WALLET_BALANCE, API_GYMS_SLOTS, API_GYMS_LOCATION, API_GYMS_SUBSCRIPTION, API_GYM_UNLOCK_SESSION, API_HC_CART,
  API_GET_PRESCRIPTION_DETAILS,API_GET_REIMBURSEMENT_CLAIM_LIST, API_GET_REIMBURSEMENT_DETAILS, API_BANK_ACCOUNT_DETAILS, API_GET_CUSTOMER_BENEFIT_SUBLIMIT, API_GET_QUESTION_RESPONSE,
  API_GET_PRESCRIPTION_RAZORPAY_DETAILS, API_RESPOND_DEFICIENCY, PWA_SSO_LOGIN, API_FETCH_LAB_TESTS_INFO, API_FETCH_POPULAR_LAB_TESTS,
  API_FETCH_POPULAR_FUNCTION_TESTS, API_FETCH_LAB_TEST_PACKAGES, API_FETCH_PREVIOUS_BOOKED_PACKAGE, API_FETCH_POPULAR_PACKAGES,
  API_FETCH_LABTEST_MEMBERS, API_NEW_USER_TO_PHARMACY, API_DEPENDENTS_PACKAGES, API_FETCH_PHARMACY_SERVICEABLE_CITIES, API_GET_GLOBAL_CART,
  API_FETCH_HEALTH_COACH_TIMELINE, API_HEALTH_COACH_APPOINTMENT_REQUEST, API_FETCH_HEALTH_COACH_DOCTOR_SLOTS,
  API_HEALTH_COACH_DOCTOR_DETAIL, API_HEALTH_COACH_CONSULTATION_REQUEST, API_UPDATE_HEALTH_COACH_VITALS,
  API_FETCH_REPORTS_UPLOAD_OPTIONS, API_DELETE_REPORTS, API_UPLOAD_REPORTS, API_FETCH_UPLOADED_RECORDS,
  API_MILESTONE_CHALLENGE, FETCH_IPD_INTEGRATION_DETAILS, FETCH_PAST_ORDERS, FETCH_PREVIOUSLY_ORDERED_ITEMS, SEARCH_PORVIDERS,
  API_GET_UPI_DETAILS, API_CREATE_CASHLESS_APPOINTMENT, API_GET_CASHLESS_APPOINTMENT_STATUS, API_GET_CASHLESS_APPOINTMENT_PAYMENT_DETAILS, API_UPLOAD_CASHLESS_APPOINTMENT_DOCUMENTS,
  CREATE_BOOKING_HEALTH_FEED_POST, GET_HEALTH_FEED_ITEMS, POLL_VOTE, ANSWER_FEED_QUESTION,
  TOGGLE_LIKE, WALLET_ALLOCATION_DETAIL, API_GET_DOCTOR_DETAILS, API_POST_BOOKING_GENERATE_PAYOUT, API_RECOMMENDED_PACKAGES, API_GET_SCAN_TO_PAY_AVAILABILITY, API_PROGRAM_ENROLLMENT_DETAILS,
  API_JOIN_MEETING
} from "../routes/api";


export default class HomeAPI {
  static fetchArticles = (options, onSuccess, onError) => {
    request({ ...options, url: API_ARTICLES + '?page=' + options.data.page }, onSuccess, onError)
  }

  static fetchMentalWellnessDetails = (options, onSuccess, onError) => {
    request({ ...options, url: API_BENEFITS_MENTAL_WELLNESS }, onSuccess, onError);
  }

  static uploadEwapAppointments = (options, onSuccess, onError) => {
    request({ ...options, url: API_UPLOAD_EWAP_APPIONTMENTS, method: 'post' }, onSuccess, onError);
  }

  static uploadEwapPostPaymentDetails = (options, onSuccess, onError) => {
    request({ ...options, url: API_EWAP_POST_PAYMENT, method: 'post' }, onSuccess, onError)
  }

  static userLogin = (options, onSuccess, onError) => {
    request({ ...options, url: API_USER_LOGIN, method: 'post' }, onSuccess, onError)
  }

  static userLogout = (options, onSuccess, onError) => {
    request({ ...options, url: API_USER_LOGOUT, method: 'delete' }, onSuccess, onError)
  }

  static fetchFamilyMembers = (options, onSuccess, onError) => {
    return request({ ...options, url: API_FETCH_FAMILY_MEMBERS(options.planId) }, onSuccess, onError)
  }

  static fetchMemberInfo = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_MEMBER_INFO(options.token) }, onSuccess, onError)
  }

  static recordMemberVaccinationDetails = (options, onSuccess, onError) => {
    request({ ...options, url: API_RECORD_MEMBER_VACCINATION_DETAILS, method: 'post' }, onSuccess, onError)
  }

  static fetchSponsorPackageDetails = (options, onSuccess, onError) => {
    request({ ...options, url: `${API_FETCH_SPONSOR_PACKAGE_DETAILS}/${options.package_id}/info` }, onSuccess, onError)
  }

  static fetchProviders = (type, planId, options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_PROVIDERS(type, planId, options?.page, options?.per_page, options?.doctorName), method: 'post' }, onSuccess, onError)
  }

  static fetchPopularPackages = (options, onSuccess, onError) => {
    request({...options, url: API_FETCH_POPULAR_PACKAGES, method: 'get' }, onSuccess, onError)
  }

  static fetchLabTest = (options, onSuccess, onError) => {
    request({...options, url: API_FETCH_LAB_TESTS_INFO, method: 'get' }, onSuccess, onError)
  }

  static fetchPreviousBookedPackages = (options, onSuccess, onError) => {
    request({...options, url: API_FETCH_PREVIOUS_BOOKED_PACKAGE, method: 'get' }, onSuccess, onError)
  }

  static fetchPopularLabTests = (options, onSuccess, onError) => {
    request({...options, url: API_FETCH_POPULAR_LAB_TESTS, method: 'get' }, onSuccess, onError)
  }

  static fetchPopularFunctionTests = (options, onSuccess, onError) => {
    request({...options, url: API_FETCH_POPULAR_FUNCTION_TESTS, method: 'get' }, onSuccess, onError)
  }

  static createAppointmentRequest = (options, onSuccess, onError) => {
    request({ ...options, url: API_CREATE_APPOINTMENT_REQUEST, method: 'post' }, onSuccess, onError)
  }

  static fetchSlots = (options, onSuccess, onError) => {
    request({...options, url: API_FETCH_SLOTS(options.benefitType), method: 'post'}, onSuccess, onError)
  }

  static fetchLabTestPackages = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_LAB_TEST_PACKAGES, params: {category: options.category } }, onSuccess, onError)
  }

  static fetchSearchedPackages = (options, onSuccess, onError) => {
    return request({ ...options, url: API_FETCH_SEARCHED_PACKAGES, method: 'post' }, onSuccess, onError)
  }

  static fetchCustomerHealthRiskAssessments = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMER_WIZARD }, onSuccess, onError)
  }

  static fileUploadToServer = (options, type) => {
    const appointment_id = options.data.get('appointment_id');
    if (type === 'edp') {
      return request({ ...options, url: API_UPLOAD_PRESCRIPTIONS, method: 'post' })
    } else if (type === 'emr') {
      return request({ ...options, url: API_DOCUMENTS_CREATE, method: 'post' })
    } else if (type === 'verifiable_documents') {
      return request({ ...options, url: API_UPLOAD_VERIFIABLE_DOCUMENT, method: 'post' })
    } else if (type === 'form_response') {
      return request({ ...options, url: API_UPLOAD_FORM_RESPONSE, method: 'post' })
    } else if (type === 'upload_reports') {
      return request({ ...options, url: API_UPLOAD_REPORTS(options.appointmentId), method: 'post' })
    } else if (type === 'cashless_appointment_documents') {
      return request({ ...options, url: API_UPLOAD_CASHLESS_APPOINTMENT_DOCUMENTS(appointment_id), method: 'post' })
    }
  }

  static downloadPrescription = (options, onSuccess, onError) => {
    request({ ...options, url: API_DOWNLOAD_PRESCRIPTION(options.prescription_id) }, onSuccess, onError)
  }

  static fetchAddresses = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_ADDRESSES }, onSuccess, onError)
  }

  static createAddress = (options, onSuccess, onError) => {
    request({ ...options, url: API_CREARE_ADDRESS, method: 'post' }, onSuccess, onError)
  }

  static updateAddress = (options, onSuccess, onError) => {
    request({ ...options, url: API_UPDATE_ADDRESS(options.id), method: 'put' }, onSuccess, onError)
  }

  static deleteAddress = (options, onSuccess, onError) => {
    request({ ...options, url: API_DELETE_ADDRESS(options.id), method: 'delete' }, onSuccess, onError)
  }

  static submitCustomerHealthRiskAssessments = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMER_WIZARD, method: 'post' }, onSuccess, onError)
  }

  static fetchWellnessReport = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMERS_WELLNESS_REPORT }, onSuccess, onError)
  }

  static fetchWellnessTrends = (options, onSuccess, onError) => {
    request({ ...options, url: API_WELLNESS_TRENDS(options.filter) }, onSuccess, onError)
  }

  static fetchBmiScoreTrends = (options, onSuccess, onError) => {
    request({ ...options, url: API_BMI_SCORE_TRENDS((options.filter)) }, onSuccess, onError)
  }

  static fetchGarminUrl = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMERS_WEARABLES_GARMIN_URL }, onSuccess, onError)
  }

  static fetchCustomerWearables = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMER_WEARABLES }, onSuccess, onError)
  }

  static createCustomerWearablesToken = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMER_WEARABLE_CREATE_TOKEN, method: 'post' }, onSuccess, onError)
  }

  static updateCustomerWearablesWhenToSync = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMERS_WEARABLES_WHEN_TO_SYNC, method: 'post' }, onSuccess, onError)
  }

  static disconnectCustomerWearables = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMER_WEARABLES, method: 'delete' }, onSuccess, onError)
  }

  static synchroniseCustomerWearables = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMERS_WEARABLES_SYNCHRONIZING, method: 'post' }, onSuccess, onError)
  }

  static fetchHealthCoachPrograms = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_HEALTH_COACH_PROGRAMS }, onSuccess, onError)
  }

  static syncSteps = (options, onSuccess, onError) => {
    request({ ...options, url: API_SYNC_STEPS, method: 'post' }, onSuccess, onError)
  }

  static fetchHealthCoachProgram = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_HEALTH_COACH_PROGRAM(options.id) }, onSuccess, onError)
  }

  static fetchHealthCoachFamilyMembers = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_HEALTH_COACH_FAMILY_MEMBERS(options.programId) }, onSuccess, onError)
  }

  static fetchHealthCoachSlots = (onSuccess, onError) => {
    request({ url: API_FETCH_HEALTH_COACH_SLOTS }, onSuccess, onError)
  }

  static programEnrollmentPaymentOption = (options, onSuccess, onError) => {
    request({ ...options, url: API_PROGRAM_ENROLLMENT_PAYMENT_OPTION }, onSuccess, onError)
  }

  static programEnrollmentPaymentOptionV3 = (options, onSuccess, onError) => {
    request({ ...options, url: API_HEALTH_COACH_PROGRAM_ENROLLMENT_PAYMENT_OPTION }, onSuccess, onError)
  }

  static programEnrollmentPaymentDetails = (id, options, onSuccess, onError) => {
    let url = `${API_FETCH_HEALTH_COACH_PROGRAMS}/${id}/payment_details`
    request({ ...options, url: url, method: 'get' }, onSuccess, onError)
  }

  static programEnrollmentPaymentDetailsV3 = (id, customer_id, options, onSuccess, onError) => {
    let url = `${API_HEALTH_COACH_PROGRAM_ENROLLMENT}/payment_details?id=${id}&customer_id=${customer_id}`
    request({ ...options, url: url, method: 'get' }, onSuccess, onError)
  }

  static hcSpeakToUs = (options, onSuccess, onError) => {
    request({ ...options, url: API_HEALTH_COACH_REQUEST, method: 'post' }, onSuccess, onError)
  }

  static createProgramEnrollment = (options, onSuccess, onError) => {
    request({ ...options, url: API_PROGRAM_ENROLLMENT, method: 'post' }, onSuccess, onError)
  }

  static createProgramEnrollmentV3 = (options, onSuccess, onError) => {
    request({ ...options, url: API_HEALTH_COACH_PROGRAM_ENROLLMENT, method: 'post' }, onSuccess, onError)
  }

  static programEnrollmentPostPayment = (options, onSuccess, onError) => {
    request({ ...options, url: API_PROGRAM_ENROLLMENT_POST_PAY, method: 'post' }, onSuccess, onError)
  }

  static programEnrollmentPostPaymentV3 = (options, onSuccess, onError) => {
    request({ ...options, url: API_HEALTH_COACH_PROGRAM_ENROLLMENT_POST_PAY, method: 'post' }, onSuccess, onError)
  }

  static addFamilyMember = (options, onSuccess, onError) => {
    request({ ...options, url: API_ADD_FAMILY_MEMBER, method: 'post' }, onSuccess, onError)
  }

  static updateFamilyMemberRelation = (options, onSuccess, onError) => {
    request({ ...options, url: API_UPDATE_FAMILY_MEMBER_RELATION(options.id), method: 'patch' }, onSuccess, onError)
  }

  static shareLoginInstructions = (options, onSuccess, onError) => {
    request({ ...options, url: API_SHARE_LOGIN_INSTRUCTIONS, method: 'post' }, onSuccess, onError)
  }

  static fetchHealthProfileData = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_HEALTH_PROFILE_DATA }, onSuccess, onError)
  }

  //Employee Wellbeing Assistance Program(EWAP)
  static fetchEwapUrl = (options, onSuccess, onError) => {
    request({ ...options, url: API_EWAP }, onSuccess, onError)
  }

  static fetchTrendsData = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_TRENDS_DATA }, onSuccess, onError)
  }

  static verifyEmail = (options, onSuccess, onError) => {
    request({ ...options, url: API_REGISTRATIONS_VERIFY_EMAIL, method: 'post' }, onSuccess, onError)
  }

  static verifyCompanyCode = (options, onSuccess, onError) => {
    request({ ...options, url: API_REGISTRATIONS_VERIFY_COMPANY_CODE, method: 'get' }, onSuccess, onError)
  }

  static registerEmployee = (options, onSuccess, onError) => {
    request({ ...options, url: API_REGISTRATIONS_REGISTER_EMPLOYEE, method: 'post' }, onSuccess, onError)
  }

  static sendOTP = (options, onSuccess, onError) => {
    request({ ...options, url: API_REGISTRATIONS_SEND_OTP, method: 'post' }, onSuccess, onError)
  }

  static verifySelfRegisterOTP = (options, onSuccess, onError) => {
    request({ ...options, url: API_VERIFY_SELF_REGISTER_OTP, method: 'post' }, onSuccess, onError)
  }

  static verifyMobileNumber = (options, onSuccess, onError) => {
    request({ ...options, url: API_REGISTRATION_VERIFY_MOBILE_NUMBER, method: 'post' }, onSuccess, onError);
  }

  static setPassword = (options, onSuccess, onError) => {
    request({ ...options, url: API_REGISTRATION_SET_PASSWORD, method: 'post' }, onSuccess, onError);
  }

  static otpLogin = (options, onSuccess, onError) => {
    request({ ...options, url: API_REGISTRATION_OTP_LOGIN, method: 'post' }, onSuccess, onError);
  }

  static fetchCustomerProfile = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_CUSTOMER_PROFILE(options.planId), method: 'get' }, onSuccess, onError);
  }

  static fetchBookingHistory = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_BOOKING_HISTORY, method: 'get' }, onSuccess, onError);
  }

  static updateCustomers = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOMERS_UPDATE, method: 'patch' }, onSuccess, onError)
  }

  static updateMobileNumber = (options, onSuccess, onError) => {
    return request({ ...options, url: API_UPDATE_MOBILE_NIUMBER, method: 'post'}, onSuccess, onError)
  }

  static verifyOtp = (options, onSuccess, onError) => {
    request({ ...options, url: API_VERIFY_OTP, method: 'post' }, onSuccess, onError)
  }

  static fetchGymsOrderSummary = ({ orderId, ...options }, onSuccess, onError) => {
    return request({ ...options, url: API_GYMS_ORDER_SUMMARY(orderId) }, onSuccess, onError)
  }

  static fetchFeedbackOptions = (options, onSuccess, onError) => {
    return request({ ...options, url: `${API_FETCH_FEEDBACK_OPTIONS}?id=${options.id}&type=${options.type}` }, onSuccess, onError)
  }

  static appointmentFeedbackSubmit = (options, onSuccess, onError) => {
    request({ ...options, url: API_APPOINTMENT_FEEDBACK_SUBMIT(options.data.id), method: 'post' }, onSuccess, onError)
  }

  static fetchEwapDateSlots = (options, onSuccess, onError) => {
    request({ ...options, url: API_EWAP_DATE_SLOTS(options.mode, options.providerId) }, onSuccess, onError)
  }

  static ewapAppointmentCancelRequest = (options, onSuccess, onError) => {
    request({ ...options, url: EWAP_APPOINTMENT_CANCEL_REQUEST(options.id), method: 'patch' }, onSuccess, onError)
  }

  static fetchEwapRescheduleDateSlots = (options, onSuccess, onError) => {
    request({ ...options, url: API_EWAP_RESCHEDULE_DATE_SLOTS(options.providerId, options.mode) }, onSuccess, onError)
  }

  static fetchEwapRescheduleTimeSlots = (options, onSuccess, onError) => {
    request({ ...options, url: API_EWAP_RESCHEDULE_TIME_SLOTS(options.date, options.providerId, options.mode, options.appointmentId) }, onSuccess, onError)
  }

  static uploadEwapRescheduleAppointments = (options, onSuccess, onError) => {
    request({ ...options, url: API_UPLOAD_RESCHEDULE_EWAP_APPIONTMENTS(options.appointmentId), method: 'patch' }, onSuccess, onError);
  }

  static fetchEwapTimeSlots = (options, onSuccess, onError) => {
    request({ ...options, url: API_EWAP_TIME_SLOTS(options.date, options.mode, options.providerId) }, onSuccess, onError)
  }

  static fetchOngoingActivities = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_ONGOING_ACTIVITIES }, onSuccess, onError)
  }

  static fetchAppointmentSlots = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_APPOINTMENT_SLOTS(options.id), params: { web: true } }, onSuccess, onError)
  }

  static appointmentReschedule = (options, onSuccess, onError) => {
    request({ ...options, url: API_RESCHEDULE_APPOINTMENT(options.data.id), method: 'patch' }, onSuccess, onError)
  }

  static voucherDownload = (options, onSuccess, onError) => {
    request({ ...options, url: API_VOUCHER_DOWNLOAD(options.id) }, onSuccess, onError)
  }

  static fetchReimbursementBenefits = ({ ...options }, onSuccess, onError) => {
    return request({ ...options, url: API_BENEFIT_REIMBURSEMENT(options.formType, options.planId), method: 'get' }, onSuccess, onError)
  }

  static fetchServices = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_SERVICES }, onSuccess, onError)
  }

  static fetchTalkWithDocData = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_TALK_WITH_DOC_DATA }, onSuccess, onError)
  }

  static fetchCustomTiles = (options, onSuccess, onError) => {
    request({ ...options, url: API_CUSTOM_TILES_GET }, onSuccess, onError)
  }

  static fetchArticleDetails = (options, onSuccess, onError) => {
    request({ ...options, url: API_ARTICLE_DETAILS_GET(options.data.id) }, onSuccess, onError)
  }

  static fileDownload = (options, onSuccess, onError) => {
    const FILE_DOWNLOAD_URL = (id) => `/v1/customers/documents/${id}/download`
    request({ ...options, url: FILE_DOWNLOAD_URL(options.id) }, onSuccess, onError)
  }

  static samlAuthenticateUser = (options, onSuccess, onError) => {
    request({ ...options, url: API_SAML_AUTHENTICATE, method: 'post' }, onSuccess, onError)
  }

  static pwassologin = (options, onSuccess, onError) => {
    request({ ...options, url: PWA_SSO_LOGIN(options.slug), method: 'post' }, onSuccess, onError)
  }

  static mediaAssistAuth = (options, onSuccess, onError) => {
    request({ ...options, url: MEDIA_ASSIST_AUTH, method: 'post' }, onSuccess, onError)
  }

  static aesAuthenticateUser = (options, onSuccess, onError) => {
    request({ ...options, url: API_AES_AUTHENTICATE, method: 'post' }, onSuccess, onError)
  }

  static fetchAllPrescriptions = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_ALL_PRESCRIPTIONS }, onSuccess, onError)
  }

  static fetchPharmacyOrderDetails = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_PHARMACY_ORDER_DETAILS(options.id) }, onSuccess, onError)
  }

  static movePrescriptionsToOtherCustomer = (options, onSuccess, onError) => {
    request({ ...options, url: API_MOVE_PRESCRIPTIONS, method: 'patch' }, onSuccess, onError)
  }

  static fetchPharmacyTrackingDetails = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_PHARMACY_TRACKING(options.id) }, onSuccess, onError)
  }

  static pharmacyOrderCancel = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_PHARMACY_ORDER_CANCEL(options.id), method: 'put' }, onSuccess, onError)
  }

  static fetchFeedbackInfo = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_FEEDBACK }, onSuccess, onError)
  }
  
  static fetchAmbulanceOnDemand = (options, onSuccess, onError) => {
    request({ ...options, url: API_AMBULANCE_ON_DEMAND }, onSuccess, onError)
  }

  static feedbackSubmit = (options, onSuccess, onError) => {
    request({ ...options, url: API_FEEDBACK_SUBMIT, method: 'post' }, onSuccess, onError)
  }

  static fetchMedicalHistory = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_MEDICAL_HISTORY }, onSuccess, onError)
  }

  static deleteAssessment = (options, onSuccess, onError) => {
    request({ ...options, url: API_DELETE_ASSESSMENT(options.id), method: 'delete' }, onSuccess, onError)
  }

  static downloadDocument = (options, onSuccess, onError) => {
    request({ ...options, url: API_DOWNLOAD_DOCUMENT(options.id) }, onSuccess, onError)
  }

  static fetchConsultationHistory = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_CONSULTATIONS_HISTORY }, onSuccess, onError)
  }

  static fetchInvoices = (options, onSuccess, onError) => {
    request({ ...options, url: API_FETCH_INVOICES }, onSuccess, onError)
  }

  static downloadInvoice = (options, onSuccess, onError) => {
    request({ ...options, url: API_DOWNLOAD_INVOICE(options.id) }, onSuccess, onError)
  }

  static downloadDoctorDocument = (options, onSuccess, onError) => {
    const { id, sendFileUrl = true } = options;
    const queryParams = sendFileUrl ? "?send_file_url=true" : "";
    const url = `${API_DOCTOR_DOCUMENTS}/${id}${queryParams}`;
    
    request({ ...options, url }, onSuccess, onError);
  };


  static fetchPlans = (options, onSuccess, onError) => {
    request({ ...options, url: API_DOCTOR_DOCUMENTS }, onSuccess, onError)
  }

  static validateCoupon = (options, onSuccess, onError) => {
    request({ ...options, url: API_VALIDATE_COUPON, method: 'post' }, onSuccess, onError)
  }

  static createWhatsappCommunication = (options, onSuccess, onError) => {
    request({ ...options, url: API_ENABLE_WHATSAPP, method: 'post' }, onSuccess, onError)
  }

  static fetchAppointmentDetails = ({ appointmentId, ...options }, onSuccess, onError) => {
    return request({ ...options, url: API_GET_APPOINTMENT_DETAILS(appointmentId) }, onSuccess, onError);
  }

  static fetchEwapToken = (options, onSuccess, onError) => {
    return request({ ...options, url: API_FETCH_EWAP_TOKEN, method: 'get' }, onSuccess, onError)
  }

  static fetchStressQuestions = (options, onSuccess, onError) => {
    request({ ...options, url: API_STRESS_QUESTIONS }, onSuccess, onError)
  }

  static submitStressResponse = (options, onSuccess, onError) => {
    request({ ...options, url: API_SUBMIT_STRESS_RESPONSE, method: 'post' }, onSuccess, onError)
  }

  static getSummaryDetails = (options, onSuccess, onError) => {
    request({ ...options, url: API_GET_SUMMARY_DETAILS }, onSuccess, onError)
  }

  static validatePincode = (options, onSuccess, onError) => {
    return request({ ...options, url: `${API_VALIDATE_PINCODE}?city=${options.city}`, method: 'get' }, onSuccess, onError)
  }

  static healthChecksPaymentOptions = (options, onSuccess, onError) => {
    request({ ...options, url: API_HEALTH_CHECKS_PAYMENT_OPTIONS, method: 'post' }, onSuccess, onError)
  }

  static verifyCustomerEmail = (options, onSuccess, onError) => {
    return request({ ...options, url: `${API_VERIFY_EMAIL}?new_email=${options.new_email}` }, onSuccess, onError)
  }

  static verifyCustomerEmailOtp = (options, onSuccess, onError) => {
    request({ ...options, url: API_VERIFY_EMAIL_OTP, method: 'post' }, onSuccess, onError)
  }

  static fecthNewUserToPharmacy = (options, onSuccess, onError) => {
    return request({ ...options, url: API_NEW_USER_TO_PHARMACY, method: 'get' }, onSuccess, onError)
  }

  static fetchNewDashboardData = (options, onSuccess, onError) => {
    request({ ...options, url: API_NEW_DASHBOARD(options.planId), method: 'get' }, onSuccess, onError)
  }

  static storeCustomerIdentity = (options, onSuccess, onError) => {
    return request({ ...options, url: API_STORE_IDENTITY, method: 'patch' }, onSuccess, onError)
  }

  static autoCompleteSearch = (options, onSuccess, onError) => {
    return request({ ...options, url: `${AUTOCOMPLETE_SEARCH}?name=${encodeURIComponent(options.searchTerm)}&city=${options.city}` }, onSuccess, onError)
  }

  static searchMedicine = (options, onSuccess, onError) => {
    return request({ ...options, url: `${SEARCH_MEDICINE}?name=${encodeURIComponent(options.searchTerm)}&city=${options.city}&scroll_id=${options.scrollId}` }, onSuccess, onError)
  }

  static searchProviders= (options, onSuccess, onError) => {
    return request({ ...options, url: SEARCH_PORVIDERS(options.benefitType,options.searchTerm), method: 'get' }, onSuccess, onError)
  }

  static marketingTiles = (options, onSuccess, onError) => {
    return request({ ...options, url: API_MARKETING_TILES, method: 'get' }, onSuccess, onError)
  }

  static fetchProductDetails = (options, onSuccess, onError) => {
    return request({ ...options, url: `${FETCH_PRODUCT_DETAILS(options.id)}?type=${options.type}&city=${options.city}`, method: 'get' }, onSuccess, onError)
  }

  static fetchByCategory = (options, onSuccess, onError) => {
    return request({ ...options, url: `${FETCH_BY_CATEGORY(options.id)}?city=${options.city}&page_number=${options.page || 0}&page_size=${options.per_page || 40}`, method: 'get' }, onSuccess, onError)
  }

  static updatePharmacyCart = (options, onSuccess, onError) => {
    return request({ ...options, url: UPDATE_CART(options.planId), method: 'post' }, onSuccess, onError)
  }

  static fetchCartDetails = (options, onSuccess, onError) => {
    return request({ ...options, url: FETCH_CART_DETAILS(options.planId), method: 'get' }, onSuccess, onError)
  }

  static fetchPharmacyTransactions = (options, onSuccess, onError) => {
    return request({ ...options, url: API_PHARMACY_TRANSACTIONS, method: 'get' }, onSuccess, onError)
  }

  static updateCovinRegiStatus = (options, onSuccess, onError) => {
    return request({ ...options, url: API_UPDATE_COVIN_REG_STATUS, method: 'patch' }, onSuccess, onError)
  }

  static newProviderRequest = (options, onSuccess, onError) => {
    return request({ ...options, url: API_NEW_PROVIDER_REQUEST, method: 'post' }, onSuccess, onError)
  }

  static fetchPharmacyOrderHistoryDetails = (options, onSuccess, onError) => {
    return request({ ...options, url: API_FETCH_PHARMACY_ORDER_HISTORY_DETAILS(options.order_id), method: 'get' }, onSuccess, onError);
  }

  static fetchGymBookingHistoryDetails = (options, onSuccess, onError) => {
    return request({ ...options, url: API_FETCH_GYM_BOOKING_HISTORY(options.appointment_id), method: 'get' }, onSuccess, onError);
  }

  static overrideMobileNumber = (options, onSuccess, onError) => {
    return request({ ...options, url: API_OVERRIDE_MOBILE_NUMBER(options.number), method: 'get' }, onSuccess, onError);
  }

  static deactivateFamilyMember = (options, onSuccess, onError) => {
    return request({ ...options, url: API_DEACTIVATE_FAMILY_MEMBER(options.token), method: 'patch' }, onSuccess, onError)
  }

  static fetchLabTestFamilyMembers = (options, onSuccess, onError) => {
    return request({ ...options, url: API_FETCH_LABTEST_MEMBERS(options.package_id), method: 'get' }, onSuccess, onError)
  }

  static fetchEligibleFamilyMembersForSearch = (options, onSuccess, onError) => {
    return request({ ...options, url: API_FETCH_ELIGIBLE_MEMBERS_FOR_SEARCHED_PACKAGE(options.package_id), method: 'get', }, onSuccess, onError)
  }

  static fetchWalletTransactionHistory = (options, onSuccess, onError) => {
    return request({ ...options, url: WALLET_TRANSACTION_HISTORY(options.planId), method: 'get' }, onSuccess, onError)
  }

  static fetchWalletAllocations = (options, onSuccess, onError) => {
    return request({...options, url: WALLET_ALLOCATION_DETAIL(options.planId), method: 'get'}, onSuccess, onError)
  }

  static fetchWalletTransactionDetail = (options, onSuccess, onError) => {
    return request({ ...options, url: WALLET_TRANSACTION_DETAIL(options.id), method: 'get' }, onSuccess, onError)
  }

  static fetchDynamicFormQuestions = (options, onSuccess, onError) => {
    return request({ ...options, url: API_GET_DYNAMIC_FORM_QUESTIONS(options.id, options.benefitId, options.reimbursementId), method: 'get' }, onSuccess, onError)
  }

  static saveFormQuestionsResponse = (options, onSuccess, onError) => {
    return request({ ...options, url: API_POST_DYNAMIC_FORM_QUESTIONS_RESPONSE(options.form_submission_id, options.id), method: 'post' }, onSuccess, onError)
  }

  static deleteUploadedFile = (options, onSuccess, onError) => {
    return request({ ...options, url: API_DELETE_UPLOAD_FORM_RESPONSE, method: 'post' }, onSuccess, onError)
  }

  static getPreviousFormQuestion = (options, onSuccess, onError) => {
    return request({ ...options, url: options.url, method: 'get' }, onSuccess, onError)
  }

  static formPostPaymentResponse = (options, onSuccess, onError) => {
    return request({ ...options, url: API_FORM_POST_PAYMENT_RESPONSE(options.form_submission_id), method: 'post' }, onSuccess, onError)
  }

  static fetchOffersList = ({ id, ...options }, onSuccess, onError) => {
    return request({ ...options, url: API_COUPON_OFFER(id) }, onSuccess, onError)
  }

  static fetchAvailbleVaccinesDose = (options, onSuccess, onError) => {
    return request({ ...options, url: API_AVAILABLE_VACCINES_DOSE, method: 'get' }, onSuccess, onError)
  }

  static fetchAhcFamilyMembers({ type, planId,...options }, onSuccess, onError) {
    return request({ ...options, url: API_FETCH_AHC_FAMILY_MEMBERS(type, planId), method: 'get' }, onSuccess, onError)
  }

  static fetchAhcPackages(options, onSuccess, onError) {
    return request({ ...options, url: API_FETCH_AHC_PACKAGES(options.type, options.customerId, options.latitude, options.longitude, options.zip_code, options.planId), method: 'get' }, onSuccess, onError)
  }

  static fetchGroupHealthInsurance = (options, onSuccess, onError) => {
    return request({...options, url: API_GROUP_HEALTH_INSURANCE, method: 'get'}, onSuccess, onError)
  }

  static fetchBenefitsSlots = (type, options, onSuccess, onError) => {
    return request({...options, url: API_FETCH_SLOTS(type), method: 'post'}, onSuccess, onError)
  }

  static cancelBenefitsAppointment = (options, onSuccess, onError) => {
    return request({ ...options, url: API_CANCEL_BENEFITS_APPOINTMENT(options.apt_id), method: 'patch' }, onSuccess, onError)
  }

  static fetchKnowMoreForWalletBalance = (type, planId, options, onSuccess, onError) => {
    request({ ...options, url: API_KNOW_MORE_WALLET_BALANCE(type, planId), method: 'get'}, onSuccess, onError)
  }
  
  static fetchSpendingAccountDetails = (options, onSuccess, onError) => {
    return request({...options, url: API_SPENDING_ACCOUNT(options.benefitType, options.planId), method: 'get'}, onSuccess, onError)
  }
  
  static fetchGymSlots = (options, onSuccess, onError) => {
    return request({...options, url: API_GYMS_SLOTS(options.latitude, options.longitude, options.date), method: 'get'}, onSuccess, onError)
  }

  static fetchGymLocation = (options, onSuccess, onError) => {
    return request({...options, url: API_GYMS_LOCATION(options.latitude, options.longitude, options.category, options.start_time, options.date), method: 'get'}, onSuccess, onError)
  }

  static fetchGymSubscriptions = (options, onSuccess, onError) => {
    return request({...options, url: API_GYMS_SUBSCRIPTION, method: 'get'}, onSuccess, onError)
  }

  static createGymUnlockSession(options, onSuccess, onError) {
    return request({ url: API_GYM_UNLOCK_SESSION(options.id), ...options, method: 'post' }, onSuccess, onError)
  }

  static healthCheckCartHistory(options, onSuccess, onError) {
    return request({ ...options, url: API_HC_CART(options.type, options.latitude, options.longitude, options.zip_code, options.address_id, options.planId), method: options.method }, onSuccess, onError)
  }

  static fetchPrescriptionDetails(options, onSuccess, onError) {
    return request({ ...options, url: API_GET_PRESCRIPTION_DETAILS(options.aptId), method: 'get' }, onSuccess, onError)
  }

  static fetchNewReimbursementClaims(options, onSuccess, onError) {
    return request({ ...options, url: API_GET_REIMBURSEMENT_CLAIM_LIST(options.tab), method: 'get' }, onSuccess, onError)
  }

  static fetchReimbursementDetails(options, onSuccess, onError) {
    return request({ ...options, url: API_GET_REIMBURSEMENT_DETAILS(options.id), method: 'get'}, onSuccess, onError)
  }

  static fetchBankAccountDetails(options, onSuccess, onError) {
    return request({ ...options, url: API_BANK_ACCOUNT_DETAILS, method: 'get'}, onSuccess, onError)
  }

  static createBankAccountDetails(options, onSuccess, onError) {
    return request({ ...options, url: API_BANK_ACCOUNT_DETAILS, method: 'post'}, onSuccess, onError)
  }

  static updateBankAccountDetails(options, onSuccess, onError) {
    return request({ ...options, url: API_BANK_ACCOUNT_DETAILS, method: 'patch'}, onSuccess, onError)
  }

  static fetchCustomerBenefitSubLimit = (options, onSuccess, onError) => {
    return request({ ...options, url: API_GET_CUSTOMER_BENEFIT_SUBLIMIT(options.benefitId, options.reimbursementId, options.formId), method: 'get' }, onSuccess, onError)
  }

  static fetchReimbursementQuestionResponse = (options, onSuccess, onError) => {
    return request({ ...options, url: API_GET_QUESTION_RESPONSE(options.reimbursementId, options.questionId), method: 'get' }, onSuccess, onError)
  }

  static fetchPrescriptionRazorpayDetails(options, onSuccess, onError) {
    return request({ ...options, url: API_GET_PRESCRIPTION_RAZORPAY_DETAILS(options.id), method: 'get' }, onSuccess, onError)
  }

  static respondDeficiency(options, onSuccess, onError) {
    return request({ ...options, url: API_RESPOND_DEFICIENCY(options.id), method: 'patch'}, onSuccess, onError)
  }

  static fetchSponsoredPackages(options, onSuccess, onError) {
    return request({ ...options, url: API_DEPENDENTS_PACKAGES(options.benefit_type, options.customer_id, options.collection_type, options.latitude, options.longitude, options.zip_code, options.planId), method: 'get'}, onSuccess, onError)
  }

  static fetchServiceableCities(options, onSuccess, onError) {
    return request({ ...options, url: API_FETCH_PHARMACY_SERVICEABLE_CITIES, method: 'get'}, onSuccess, onError)
  }

  static fetchGlobalCart(options, onSuccess, onError) {
    return request({...options, url: API_GET_GLOBAL_CART(options.planId), method: 'get' }, onSuccess, onError)
  }

  static fetchHealthCoachProgramTimeline(options, onSuccess, onError) {
    return request({ ...options, url: API_FETCH_HEALTH_COACH_TIMELINE(options.enrollmentId), method: 'get' }, onSuccess, onError)
  }

  static createHealthCoachAptRequest(options, programEnrollmentId = '', methodName = 'post', onSuccess, onError) {
    return request({ ...options, url: API_HEALTH_COACH_APPOINTMENT_REQUEST(programEnrollmentId), method: methodName}, onSuccess, onError)
  }

  static createHealthCoachConsultationRequest(options, onSuccess, onError) {
    return request({ ...options, url: API_HEALTH_COACH_CONSULTATION_REQUEST, method: 'post'}, onSuccess, onError)
  }

  static fetchHealthCoachConsultationRequest(options, onSuccess, onError) {
    return request({ ...options, url: API_HEALTH_COACH_DOCTOR_DETAIL(options.timelineId), method: 'get' }, onSuccess, onError)
  }

  static fetchHealthCoachDoctorSlots(options, onSuccess, onError) {
    return request({ ...options, url: API_FETCH_HEALTH_COACH_DOCTOR_SLOTS(options.doctorId), method: 'get' }, onSuccess, onError)
  }
  
  static updateHealthCoachVitals(options, onSuccess, onError) {
    return request({ ...options, url: API_UPDATE_HEALTH_COACH_VITALS, method: 'post'}, onSuccess, onError)
  }

  static fetchReportsAsUploadOptions = (options, onSuccess, onError) => {
    return request({...options, url: API_FETCH_REPORTS_UPLOAD_OPTIONS(options.appointmentId), method: 'get'}, onSuccess, onError)
  }

  static deleteReports = (options, onSuccess, onError) => {
    request({ ...options, url: API_DELETE_REPORTS(options.type, options.id, options.aptId), method: 'delete' }, onSuccess, onError)
  }

  static fetchUploadedRecords = (options, onSuccess, onError) => {
    return request({...options, url: API_FETCH_UPLOADED_RECORDS(options.aptId), method: 'get'}, onSuccess, onError)
  }

  static fetchMilestoneChallenge = (options, onSuccess, onError) => {
    return request({...options, url: API_MILESTONE_CHALLENGE, method: 'get'}, onSuccess, onError)
  }
  
  static fetch_ipd_integration_details = (options, onSuccess, onError) => {
    request({ ...options, url: FETCH_IPD_INTEGRATION_DETAILS, method: 'get' }, onSuccess, onError)
  }

  static fetchPharmacyPastOrders = (options, onSuccess, onError) => {
    return request({...options, url: FETCH_PAST_ORDERS(options.page), method: 'get'}, onSuccess, onError)
  }

  static fetchPreviouslyOrderedItems = (options, onSuccess, onError) => {
    return request({...options, url: FETCH_PREVIOUSLY_ORDERED_ITEMS(options.city, options.pageNo, options.perPage), method: 'get'}, onSuccess, onError)
  }

  static getUPIdetails = (options, onSuccess, onError) => {
    request({...options, url: API_GET_UPI_DETAILS(options.upi_id), method: 'get' }, onSuccess, onError)
  }

  static createCashlessInclinicApt = (options, onSuccess, onError) => {
    request({...options, url: API_CREATE_CASHLESS_APPOINTMENT, method: 'post' }, onSuccess, onError)
  }

  static cashlessAptStatus = (options, onSuccess, onError) => {
    request({...options, url: API_GET_CASHLESS_APPOINTMENT_STATUS(options.payout_id) , method: 'get' }, onSuccess, onError)
  }

  static uploadCashlessInclinicAptDocumets = (options, onSuccess, onError) => {
    request({...options, url: API_UPLOAD_CASHLESS_APPOINTMENT_DOCUMENTS(options.aptId), method: 'get' }, onSuccess, onError)
  }

  static cashlessInclinicAptPaymentDetails = (options, onSuccess, onError) => {
    request({...options, url: API_GET_CASHLESS_APPOINTMENT_PAYMENT_DETAILS(options.aptId), method: 'get' }, onSuccess, onError)
  }

  static postBookingGeneratePayout = (options, onSuccess, onError) => {
    request({...options, url: API_POST_BOOKING_GENERATE_PAYOUT(options.uniqueId), method: 'post' }, onSuccess, onError)
  }

  static scanToPayAvailable = (options, onSuccess, onError) => {
    request({...options, url: API_GET_SCAN_TO_PAY_AVAILABILITY, method: 'get' }, onSuccess, onError)
  }

  static createBookingHealthFeedPost = (options, onSuccess, onError) => {
    return request({...options, url: CREATE_BOOKING_HEALTH_FEED_POST, method: 'post'}, onSuccess, onError)
  }

  static getHealthFeedItems = (options, onSuccess, onError) => {
    return request({...options, url: GET_HEALTH_FEED_ITEMS(options.page), method: 'get'}, onSuccess, onError)
  }

  static pollVote = (options, onSuccess, onError) => {
    return request({...options, url: POLL_VOTE, method: 'post'}, onSuccess, onError)
  }

  static answerFeedQuestion = (options, onSuccess, onError) => {
    return request({...options, url: ANSWER_FEED_QUESTION, method: 'post'}, onSuccess, onError)
  }

  static toggleLike = (options, onSuccess, onError) => {
    return request({...options, url: TOGGLE_LIKE(options.id), method: 'post'}, onSuccess, onError)
  }

  static getDoctorDetails = (options, onSuccess, onError) => {
    return request({...options, url: API_GET_DOCTOR_DETAILS(options.id), method: 'get'}, onSuccess, onError)
  }

  static fetchRecommendedPackages(options, onSuccess, onError) {
    return request({ ...options, url: API_RECOMMENDED_PACKAGES(options.benefit_type, options.customer_id, options.latitude, options.longitude, options.zip_code, options.planId), method: 'get'}, onSuccess, onError)
  }

  static fetchEnrolledProgramDetails(options, onSuccess, onError) {
    return request({ ...options, url: API_PROGRAM_ENROLLMENT_DETAILS(options.id), method: 'get'}, onSuccess, onError)
  }
  static joinMeeting(options, onSuccess, onError){
    return request({ ...options, url: API_JOIN_MEETING(options.id), method: 'get'}, onSuccess, onError)
  }
}
