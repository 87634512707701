import React, { useState, lazy } from 'react';
import Input from './Input'; 
import styled, { css } from 'styled-components';
import { addMinutes, compareAsc, format, isToday, setHours, setMinutes } from 'date-fns';
const LazyDatePicker = lazy(() => import('./DatePickerLazy'));

const ExtendedInput = styled(Input)`
  color: #475569;
  word-spacing: 0px;
  text-align: ${props => props.textAlign };
  ${({disabled}) => disabled && css`
    pointer-events: none;
  `}
`

const CustomDatePicker = ({onChange, filterPassedTime, dob, placeholder, showYearDropdown, showMonthDropdown, showTimeSelect, selectedDob, disabled, textAlign, width, color, onChangeKey, minDate, dateFormat='dd-MM-yyyy', height, borderColor}) => {
  const [startDate, setStartDate] = useState(null);
  
  const customChange = (event) => {
    let newDate = new Date(event);
    const currentTime = new Date();
    const formattedDate = format(new Date(event), 'dd-MM-yyyy');
    if (isToday(newDate) && showTimeSelect) {
      const minutesToAdd = 30 - (currentTime.getMinutes() % 30);
      const roundedUpTime = addMinutes(currentTime, minutesToAdd);
  
      if (compareAsc(newDate, roundedUpTime) !== 1) {
        newDate = setHours(setMinutes(newDate, roundedUpTime.getMinutes()), roundedUpTime.getHours());
      }
    }
    if (onChangeKey) {
      onChange(event);
    } else {
      onChange(formattedDate);
    }
    setStartDate(newDate);
  }

  const ExampleCustomInput = ({ value, onClick }) => (
    <ExtendedInput bold disabled={disabled} textAlign={textAlign} color={color} fontSize='md' placeholder={placeholder ?? "DD/MM/YYYY"} id='date-picker' width={width} height={height} value={value} onClick={onClick} borderColor={borderColor} autoComplete="off" />
  );

  return (
    <LazyDatePicker
      selected={startDate}
      onChange={customChange}
      customInput={<ExampleCustomInput />}
      dateFormat={dateFormat}
      maxDate={dob ? (new Date()) : false}
      filterTime={(time) => {
        // For today, filter out times before the current time
        if (isToday(new Date())) {
          const currentTime = new Date();
          const selectedTime = new Date(time);
          return selectedTime.getTime() >= currentTime.getTime();
        }
        return true; // Allow all times for future dates
      }}
      minDate={minDate}
      value={selectedDob ? selectedDob : startDate}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      filterPassedTime={filterPassedTime}
      showTimeSelect={showTimeSelect}
      dropdownMode="select"
    />
  )
} 

CustomDatePicker.defaultProps = {
  showMonthDropdown: true,
  showYearDropdown: true,
  showTimeSelect: false,
  textAlign: 'center',
  width: '100%',
  color: '#a4bocc'
}

export default CustomDatePicker;