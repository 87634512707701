import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import Input from './Input';
import { Text } from '../atoms/typography';
import Icon from './Icon';
import { handleActionKeyPress } from '../../utils/helper/keyboardKeyPress';

const DivWthBorder = styled(Flex)`
  align-items: center;
  background-color: ${props => props.theme.colors[props.backgroundColor]};
  border-radius: ${props => props.borderRadius};
  border: 1px solid ${props => ({theme: { borders }}) => props.validNumber ? 'red' : borders[props.borderColor]};
  ${({bt}) => bt && css` border-bottom:${bt} `};
  height:  ${props => props.height};
  color:  ${props => props.borderColor};
  ${({disabled}) => disabled && css`
    pointer-event: none;
  `}
  ${({width}) => `
    width: ${width};
  `}
`
const ExtendedInput = styled(Input)`
    border: none;
    margin: ${props => props.margin};
    background-color: ${props => props.theme.colors[props.backgroundColor]};
    display : flex;
    flex-grow : 2;
    font-size: ${({ theme: { fontSizes }}) => fontSizes.md};
    color: ${({ theme: { colors }, disabled}) => disabled ? colors.disabledText : colors.extraInformation};
    font-family: "Figtree-SemiBold";   
    ::-webkit-input-placeholder {
      color: ${props => props.placeholderColor ? props.theme.colors[props.placeholderColor] : 'inherit'};
    }
  ` 

const InputWithAction = ({onKeyUp, focusRef, value, onChange, disabled, onClick, placeholder, textColor, actionText, hideText, inputType, onBlur, url, validNumber, type, borderRadius, bt, name, leftSide, rightSide, ...props}) => {
  return (
    <DivWthBorder backgroundColor={props.backgroundColor} borderColor={props.borderColor} validNumber={type === 'mobile' && !validNumber} disabled={disabled} borderRadius={borderRadius} height={props.height} width={props?.width} bt={bt}>
      {url && leftSide && <Icon mt={'0'} src={url} size='md' ml={'14px'} opacity={disabled ? '0.5' : '1'} />}
      <ExtendedInput width={props.inputWidth} margin={props.margin} name={name} placeholderColor={props.placeholderColor} value={value} ref={focusRef} onKeyUp={onKeyUp} height={props.inputHeight} backgroundColor={props.backgroundColor} borderColor={props.borderColor} autoFocus={props.autoFocus} disabled={disabled} url={url} data-inputtype={inputType} placeholder={placeholder} mt={'10px'} data-testid='enter-coupon' onBlur={onBlur} onChange={onChange} />
      { !hideText && <Text size='sm' cursor='pointer' letterSpacing='0' pr={5} pl={props.pl} bold justifyContent='flex-end' disabled={disabled} data-testid='submit-coupon' color={textColor} tabIndex={0} onKeyUp={(e) => handleActionKeyPress(e, onClick)} aria-label={actionText} onClick={onClick} flexGrow={1}>{actionText}</Text>}
      {url && rightSide && <Icon mt={'0'} src={url} size='md' mr={'12px'} opacity={disabled ? '0.5' : '1'} />}
    </DivWthBorder>
  )
}

InputWithAction.defaultProps = {
  borderColor: 'input',
  backgroundColor: 'backgroundColor',
  height: '52px;',
  hideText: false,
  inputType: 'input',
  disabled: false,
  borderRadius: '4px',
  autoFocus: false,
  inputHeight: '44px',
  onChange: () => {},
  onKeyUp: () => {},
  focusRef: null,
  rightSide: true,
  margin: '2px',
  pl: 5
}

export default InputWithAction;