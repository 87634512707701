import React from 'react';
import {Flex} from 'rebass';
import {Text} from '../../atoms/typography'
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { directionRight } from '../../../assets/icons';
import { Icon } from '../../atoms';
import tracker from '../../../utils/tracker'
import {ToolTip} from '../../atoms'
import { media } from '../../../utils/mediaBreakpoints';
import { MOBILE_ACCOUNT, WEARABLES_NEW } from '../../../routes/private';
import { handleActionKeyPress } from '../../../utils/helper/keyboardKeyPress'

const IconContainer = styled.div`
    height: 100%;
    margin: auto 10px;
    padding-left: 5px;
    ${ media.mobile `
       margin-top: 6px;
       min-width : 32px;
       min-height : 32px;
       padding: 0px; 
    `}
`
const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    padding: 5px;
    // margin: ${({margin}) => margin || '0 10px'};
`

const SubSubHeader = styled.div`
    font-size: ${({ theme: { fontSizes }}) => fontSizes.md};
    color: ${props => props.theme.colors[props.color]};
    margin: 3px 0;
    font-family: ${({ theme: { fonts }}) => fonts.semiBold};

`

const RouteLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme: { colors }}) => colors.blue};
    font-size: 12px;
    background-color: ${({ theme: { colors }}) => colors.lighterBlue};
    border-radius: 10px;
    padding: 1px 10px;
    margin: 2px 0;
    width: max-content;
    &:hover {
      background-color: ${({ theme: { colors }}) => colors.blue};
      color: ${({ theme: { colors }}) => colors.lightestBlue};
    }
`
const DirectionContainer = styled.div`
    display: flex;
    cursor: pointer;
    opacity: 0.6;
    align-items: center;
    margin-left: auto;
    margin-right: 15px;
`

/**
* Pass in the components through the arguments. if no arguments passed defaults are used.
* with the link: if to and text are present renders default styles. if link is a object render the react component.
* Can add a table-cell without an icon when required falg is false.
*/
const ListCell = ({
  history, header, subHeader,muted, packageSubType, isLast = false,
  subSubHeader, icon, link, direction, onClick, collectionType, toolTipMsg,
  textMargin, eventName, eventData, calledFromSponserPackages, subSubHeaderColor, Icons, packageId, onClickViewButton, openTypesofPackagesModal, isBottomBordered = false
}) => {
  const { size } = icon;
  const onLinkClick = (e, ...args) => {
    e.preventDefault()
    onClick && onClick(...args)
    if(link.external) {
      window.open(link.to, '_blank')
    } else if(link.to) {
      if (link.to == WEARABLES_NEW) {
        window.localStorage.setItem('wearableBackUrl', MOBILE_ACCOUNT)
      }
      history.push(link.to)
    } 
    eventData ? tracker.track(eventName, eventData) : tracker.track(eventName)
  }
  return (
    <div className={`flex bg-white pl-3.5 py-2 cursor-pointer ${isLast ? '' : 'border-b'} border-gray-200 w-full ${isBottomBordered ? 'border-b' : ''} hover:bg-gray-100`} onClick={onLinkClick} tabIndex='0' onKeyUp={(e) => handleActionKeyPress(e, onLinkClick, e) } >
      {(icon.required && !React.isValidElement(icon) || React.isValidElement(icon)) && <IconContainer>
        {React.isValidElement(icon) ? icon : icon.src ? <Icon src={icon.src} alt={icon.alt} size={size || 'xxl'} mt={icon?.mt || '0px'}/> : null}
      </IconContainer>}
      <TextContainer margin={textMargin}>
        {header && <p aria-label={header} className={`${muted ? 'text-slate-600' : 'text-slate-800'} font-fig-semibold text-base`}>{header}</p>}
        <Flex>
          {subHeader && <p aria-label={subHeader} className={`${muted ? 'text-slate-500' : 'text-slate-600'} font-medium text-sm`}>{subHeader}</p>}
          {calledFromSponserPackages && packageSubType !== 'covid' && <Text fontSize='sm' bold color={muted ? 'darkGreyNew' : 'cornflowerBlue'} mt= '3px' ml='6px' onClick={()=> onClickViewButton(packageId)}>View</Text>}
        </Flex>
        {link && (React.isValidElement(link) ? link : (link.text && link.to) ? <RouteLink to={link.to}>{link.text}</RouteLink> : null)}
        {subSubHeader ? <SubSubHeader color={muted ? 'darkGreyNew' : subSubHeaderColor}>{subSubHeader}</SubSubHeader> : null}
        {calledFromSponserPackages && <Flex>
          {Icons.map((icon,idx)=>{
            return (
              <Flex mt='10px' mr='10px' key ={idx}>
                <Icon src={icon.src} />
                {collectionType !== icon.id && collectionType !==null ? <ToolTip fontSize='14px' fontFamily={'Semibold'} message={toolTipMsg} position='top-left' color='extraInformation' ><Text fontSize='sm' color={collectionType === icon.id || collectionType === null ? 'subtitle' : 'darkGreyNew'} bold ml='5px' onClick={()=>{openTypesofPackagesModal(true)}}>{icon.collectionType}</Text></ToolTip>:
                  <Text fontSize='xs' color={collectionType === icon.id || collectionType === null ? 'subtitle' : 'darkGreyNew'} bold ml='5px' onClick={()=>{openTypesofPackagesModal(true)}}>{icon.collectionType}</Text>}
              </Flex>
            )
          })}
        </Flex>}
      </TextContainer>
      {direction && 
        <DirectionContainer>
          {
            React.isValidElement(direction) ? direction :
              <Icon src={icon.secondary && icon.secondary.src || directionRight} size={icon.secondary && icon.secondary.size || 'sml'} />
          }
        </DirectionContainer>
      }
    </div>)
}

ListCell.defaultProps = {
  icon: {},
  link: {},
  border: false,
  direction: true,
  padding: '10px 0px',
  as: Link
}

export default withRouter(ListCell);