import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { CardTitle, CardSubTitle } from '../atoms';

const TitleWithSubTitleContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    margin: ${props=>props.margin};
    align-items: ${({ alignItems }) => alignItems};
`
const TitleWithSubTitle = ({ title, subTitle, alignItems, mb, ...props }) => (
  <TitleWithSubTitleContainer alignItems={alignItems} {...props}>
    <CardTitle aria-label={title} tabIndex={0} pb='0' mb='0' color={props.color} bold fontSize={props.titleFontSize} letterSpacing={props.letterSpacing}>{title}</CardTitle>
    {subTitle && <CardSubTitle aria-label={subTitle} tabIndex={0} mb={mb} pb='0.6em' color={props.subTitleColor} fontSize={props.subTitleFontSize} bold={props.subTitleFontWeight === 'bold'} bolder={props.subTitleFontWeight === 'bolder'}>{subTitle}</CardSubTitle>}
  </TitleWithSubTitleContainer>
)

TitleWithSubTitle.defaultProps = {
  alignItems: 'flex-start',
  title: null,
  subTitle: null,
}

export default TitleWithSubTitle;