import React from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { Text } from '../atoms/typography'

const TextWrapper = styled(Flex)`
  flex-direction: column;
  width: ${p => p.width || '100%'};
`;

const FormattedTexts = ({
  mt, width, alignItems,padding, fontSize='mdL', 
  boldText, normalText, color, bold=false, subtextColor,
  letterSpacing, subTextFontSize, subTextLineHeight='1.5em', subTextPr, mb
}) => {
  return (
    <TextWrapper mt={mt} width={width} alignItems={alignItems} padding={padding} mb={mb}>
      <Text color={color || 'extraInformation'} fontSize={fontSize} letterSpacing={letterSpacing ?? '0px'} bold>{boldText}</Text>
      <Text color={subtextColor || 'subtitle'} fontSize={subTextFontSize} lineHeight={subTextLineHeight} bold={bold} pr={subTextPr}>{normalText}</Text>      
    </TextWrapper>
  )
}

export default FormattedTexts