import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { CardTitle } from './typography';

const Container = styled(Box)`
  padding: ${props => props.p || '1px 5px'};
  background-color: ${props => !props.variant ? props.bgColor : props.theme.tags[props.variant].backgroundColor};
  width: fit-content;
  border-radius: 15px;
  white-space: ${props => props.whiteSpace};
`

const Title = styled(CardTitle)`
	color: ${props => !props.variant ? props.textColor : props.theme.tags[props.variant].color}
`

const Tag = ({variant, fontSize = 'sm', text, bold, bgColor, textColor, p, whiteSpace}) => {
  return(
    <Container variant={variant} bgColor={bgColor} p={p} whiteSpace={whiteSpace}>
      <Title tabIndex={0} aria-label={text} bold={bold} fontSize={fontSize} mb='0px' pb='0px' variant={variant} textColor={textColor}>{text}</Title>
    </Container>
  )
}

export default Tag;