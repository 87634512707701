import React from 'react';
import { Input, Label } from '../atoms';
import { ErrorLabel } from '../molecules';
import { Flex } from 'rebass';

const InputWithLabel = ({label, input, errors, flexDirection}) => {
  const { size, ...restLabelProps } = label;
  return (
    <Flex flexDirection={flexDirection} >
      {
        React.isValidElement(label) ? 
          label
          : 
          <Label aria-label={label.text} tabIndex={0} size={size || 'sm'} {...restLabelProps}>{label.text}</Label>
      }
      {
        React.isValidElement(input) ? 
          input
          :
          <Input {...input} data-testid={`${input.name}-input`} style={{ 
            color: 'bannerBackground', 
            backgroundColor: 'backgroundColor' 
          }}/>
      }
      {errors && <ErrorLabel width={input.width || '100%'}>{errors.join(', ')}</ErrorLabel>}
    </Flex>
  )
}

InputWithLabel.defaultProps = {
  label: {size: 'sm'},
  input: {},
  flexDirection: 'column',
  width: '100%'
}

export default InputWithLabel;