import { useState, useEffect, useRef } from 'react'

const useRequestPermissions = (requestedPermissions, afterPermissionsStateChange) => { 
  const [permissionsState, setPermissionsState] = useState(
    {
      'storage': 'prompt',
      'geolocation': 'prompt',
      'camera': 'prompt',
      'notification': 'prompt',
    }
  )

  const permissionsRef = useRef({
    'storage': 'prompt',
    'geolocation': 'prompt',
    'camera': 'prompt',
    'notification': 'prompt',
  })

  const requestNativePermissionsData = (requestedPermissions) => {
    let permissionsEventData = JSON.stringify({
      action: 'fetchPermissionsData',
      payload: {
        permissions: requestedPermissions
      }
    })
    window.postAndroidMessage(permissionsEventData)
    window.postIOSMessage(permissionsEventData)
  }

  const isPermissionGranted = (permission) => {
    return permissionsState[permission] === 'granted'
  }

  const isPermissionDenied = (permission) => {
    return permissionsState[permission] === 'denied'
  }

  const handlePermissionStatusChange = (event) => {
    let permissionsToChange = event.detail
    permissionsRef.current = { ...permissionsRef.current, ...permissionsToChange };
    setPermissionsState(permissionsRef.current)
    if(afterPermissionsStateChange) afterPermissionsStateChange()
  }

  const requestNativePermissions = (permissions) => {
    let permissionRequestEvent = JSON.stringify({
      action: permissions.includes('notification') ? 'requestAppPermissions' : 'requestPermissions',

      payload: {
        permissions: permissions
      }  
    })
    window.postAndroidMessage(permissionRequestEvent)
    window.postIOSMessage(permissionRequestEvent)
  }

  useEffect(() => {
    if (window.isEkincareApp()) {
      requestNativePermissionsData(requestedPermissions) 
    }
  }, [])

  useEffect(() => {
    window.addEventListener('permission-status-changed', handlePermissionStatusChange);

    return () => {
      window.removeEventListener('permission-status-changed', handlePermissionStatusChange);
    };
  }, []);


  return { permissionsState, isPermissionGranted, isPermissionDenied, requestNativePermissions }
}  

export default useRequestPermissions;

