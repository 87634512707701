import React from 'react';

const CircleWithDynamicText = ({width, ariaLabel, ariaHidden, circleWidth, circleHeight, circleRadius, height, text, backgroundColor="#D3DBE6", fontFamily='Figtree-Medium', color='#333333', fontSize='15px', onClick}) => {
  return (
    <svg aria-label={ariaLabel} aria-hidden={ariaHidden} width={width} height={height} onClick={onClick}>
      <circle cx={circleWidth || width/2} cy={circleHeight || height/2} r={circleRadius || width/4} fill={backgroundColor} />
      <text x="50%" y="50%" textAnchor="middle" fontSize={fontSize} dy=".3em" fill={color} style={{fontFamily: fontFamily}}>{text}</text>
    </svg>
  )
}

export default CircleWithDynamicText