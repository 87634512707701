export const handleActionKeyPress = (event, callback = () => {}, ...args) => {
  event.preventDefault()
  if (event.key === 'Enter' || event.key === ' ') {
    callback(...args);
  }
};

export const handleFocus = (e) => {
  e.target.style.outline = '2px solid #007bff';
  e.target.style.outlineOffset = '2px';
};

export const handleBlur = (e) => {
  e.target.style.outline = 'none';
};

export const BACK_BUTTON_PROPS = {
  "aria-label": "Go back to the previous screen",
  role: "button",
  tabIndex: 0
};