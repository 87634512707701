import React, { useState, useRef, useEffect } from 'react'
import { dropDownGrayTriangle,dropDownUpGrayTriangle} from '../../assets/icons'
import { handleActionKeyPress, handleBlur, handleFocus } from '../../utils/helper/keyboardKeyPress'

const SelectInput = ({ name = '', data = [''], values = [], labelTag='', placeholder = 'Select', label = '', value = '', position = 'bottom', bgColor='bg-gray-100', labelClasses = 'text-slate-700', selectClasses = 'p-4', listClasses = '', listItemClasses = 'p-6 pb-5', classes = '', error = '', id = '', inputBoxClass='', lineHeight='', disable= false, onChangeHandler = () => {}, openIcon=dropDownUpGrayTriangle, closeIcon=dropDownGrayTriangle }) => {
  const [open, setOpen] = useState(false)
  const selectRef = useRef()
  const btnRef = useRef()
  const handler = (value) => {
    setOpen(false)
    if(!value) return
    onChangeHandler({name, value},id)
  }

  useEffect(() => {
    const handler = (event) => {
      if(!(event.target === selectRef.current || event.target === btnRef.current)) setOpen(false)
    }
    document.body.addEventListener('click', handler)
    return () => document.body.removeEventListener('click', handler)
  }, [])

  const valuesLen = values.length
  return (
    <div>
      <div className={`relative ${classes}`}>
        {label && <label className={`font-fig-semibold ${labelTag ? 'mb-1': 'mb-3'} block ${labelClasses}`}>{label}</label>}
        {labelTag && <label className={`mb-3 block ${labelClasses}`}>{labelTag}</label>}

        <button 
          ref={btnRef}
          type="button" 
          disabled={disable}
          onClick={() => setOpen(val => !val)}
          className={`${bgColor} ${selectClasses} relative w-full border border-gray-200 rounded-md text-left cursor-pointer ${disable ? 'cursor-not-allowed' : 'cursor-pointer'} focus:outline-none ${inputBoxClass}`}
        >
          <input 
            ref={selectRef} 
            name={name} 
            aria-label={value ? `selected option is ${value}` : ''}
            value={value ? value : placeholder} 
            className={`${bgColor} block focus:ring-0 border-transparent focus:border-transparent w-full pr-4 text-base font-fig-semibold ${disable ? `cursor-not-allowed` : `cursor-pointer`} ${value ? 'text-slate-700':'text-gray-300'} ${lineHeight}`}          />

          <span className="absolute inset-y-0 right-4 flex items-center pointer-events-none">
            <img alt='' src={open ? openIcon : closeIcon} />
          </span>
        </button>

        <ul 
          className={`${listClasses} absolute z-10 w-full bg-white max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${open ? '':'hidden'} ${position === 'top' ? '-top-28 mb-2' : 'mt-2 shadow-lg'}`} 
        >
          {data.map((item, index) => {
            const value = index < valuesLen ? values[index] : item
            return (
              <li
                key={item}
                onClick={() => handler(value)}
                className={`${listItemClasses} text-slate-900 cursor-pointer select-none relative hover:bg-blue-100`}
              >
                <span 
                  tabIndex='0'
                  aria-label={`select ${item}`}
                  onFocus={handleFocus} 
                  onBlur={handleBlur} 
                  onKeyUp={(e) => handleActionKeyPress(e, handler, value)} 
                  className="block truncate text-base font-fig-semibold text-slate-600"
                >
                  {item}
                </span>
              </li>
            )
          })}
        </ul>
      </div>
      <div className={'flex flex-row items-center'}>
        <p className='mb-0 text-red-600'>{error}</p>
      </div>
    </div>
  )
}

export default SelectInput