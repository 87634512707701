/* eslint-disable */
import {
    SET_BOOKING_HISTORY_DATA,
    SET_CANCEL_APPOINTMENT_DATA,
    CLOSE_CANCEL_APPOINTMENT_CONFIRM_MODAL,
    OPEN_CANCEL_APPOINTMENT_CONFIRM_MODAL,
    CLOSE_CANCEL_APPOINTMENT_MODAL,
    OPEN_CANCEL_APPOINTMENT_MODAL,
    OPEN_BOOKING_HISTORY_MODAL,
    CLOSE_BOOKING_HISTORY_MODAL,
    OPEN_RESCHEDULE_APPOINTMENT_MODAL,
    OPEN_RESCHEDULE_APPOINTMENT_FROM_CANCEL_MODAL,
    CLOSE_RESCHEDULE_APPOINTMENT_FROM_CANCEL_MODAL,
    CLOSE_RESCHEDULE_APPOINTMENT_MODAL,
    SET_FEEDBACK_RATING,
    LOADING,
    TAB_CHANGE,
    FILTER_CHANGE,
    OPEN_FILTER_MODAL,
    CLOSE_FILTER_MODAL
  } from './Actions'
  
  const BookingHistoryReducer = (state, action) => {
    switch (action.type) {
    case SET_BOOKING_HISTORY_DATA:
      return { ...state, booking_history_details: action.payload, loading: false }
    case SET_CANCEL_APPOINTMENT_DATA:
      return { ...state, cancel_modal_data: action.payload }
    case CLOSE_CANCEL_APPOINTMENT_MODAL:
      return { ...state, show_cancel_appointment_modal: false, cancel_modal_data: null }
    case OPEN_CANCEL_APPOINTMENT_MODAL:
      return { ...state, show_cancel_appointment_modal: true, cancel_modal_data: action.payload }
    case CLOSE_CANCEL_APPOINTMENT_CONFIRM_MODAL:
        return { ...state, show_cancel_appointment_confirm_modal: false, cancel_modal_data: null }
    case OPEN_CANCEL_APPOINTMENT_CONFIRM_MODAL:
        return { ...state, show_cancel_appointment_confirm_modal: true, cancel_modal_data: action.payload }
    case OPEN_BOOKING_HISTORY_MODAL:
      return { ...state, show_booking_history_modal: true, order: action.payload }
    case CLOSE_BOOKING_HISTORY_MODAL:
      return { ...state, show_booking_history_modal: false, order: null }
    case OPEN_RESCHEDULE_APPOINTMENT_MODAL:
      return { ...state, open_reschedule_appointment_modal: true, selected_appointment: action.payload }
    case OPEN_RESCHEDULE_APPOINTMENT_FROM_CANCEL_MODAL:
      return { ...state, open_reschedule_appointment_from_cancel_modal: true, selected_appointment: action.payload }
      case CLOSE_RESCHEDULE_APPOINTMENT_FROM_CANCEL_MODAL:
        return { ...state, open_reschedule_appointment_from_cancel_modal: false }  
    case CLOSE_RESCHEDULE_APPOINTMENT_MODAL:
      return { ...state, open_reschedule_appointment_modal: false }
    case SET_FEEDBACK_RATING: 
      return { ...state }
    case LOADING:
      return { ...state, loading: action.payload}
    case TAB_CHANGE:
      return {...state, currentTabIndex: action.payload}
    case FILTER_CHANGE:
      return {...state, filters: action.payload}
    case OPEN_FILTER_MODAL:
      return { ...state, show_filter_modal: true }
    case CLOSE_FILTER_MODAL:
        return { ...state, show_filter_modal: false }
    default:
      return { ...state }
    }
  }
  
  export default BookingHistoryReducer