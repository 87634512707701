import React from 'react'
import { ISOS, targetName } from '../../utils/constants'
import { useUserDetails } from '../../utils/customHooks';
import { formatNumberWithCommas } from '../../utils/common';
import { ekincarePoint } from '../../assets/images/benefits';
import { Icon } from '../atoms';
import { useHistory } from 'react-router-dom';
import tracker from '../../utils/tracker';
import { REWARDS } from '../../routes/private';
import { handleActionKeyPress } from '../../utils/helper/keyboardKeyPress';

const HPoints = ({marginRight='mr-2'}) => {
  const { push } = useHistory();
  const { rewards, services, user, expiringRewardPoints } = useUserDetails();
  const rewardText = rewards?.rewardsPoints > 0 ? formatNumberWithCommas(rewards?.rewardsPoints) : 'Earn H-Points';
  const onClickRewards = () => {
    push(REWARDS)
    tracker.track('hs_hpoints', {hpoints: parseInt(rewards.rewardsPoints) || 0})
  }
  return (
    <div>
      {( targetName !== ISOS && !user.has_remote_wallet && services?.enable_rewards !== 0 ) && 
        <div id={expiringRewardPoints > 0 ? "reward-box-container" : ""} tabIndex={'0'} onKeyUp={(e) => handleActionKeyPress(e, onClickRewards)} onClick={onClickRewards} className={`flex justify-between items-center border rounded-lg cursor-pointer transition-transform duration-500 transform hover:scale-110 ${marginRight} py-1.5 px-2.5 sm:mr-4`}>
          <Icon size={'lg'} pb={1} mr={3} src={ekincarePoint} alt="Exp Points"/>
          {expiringRewardPoints > 0 && <div className='overflow-hidden relative h-6 w-fit'>
            <div className='vertical-rotator flex flex-col'>
              <div className='flex flex-col h-9'>
                <p className='text-sm font-fig-extrabold leading-3' style={{color: "#e74c3c"}}>{formatNumberWithCommas(expiringRewardPoints)}</p>
                <div className='text-xs font-fig-semibold leading-3'>expiring</div>
              </div>
              <div className='flex items-center h-6'>
                <p className='text-sm font-fig-extrabold text-slate-600 leading-3'>{rewardText}</p>
              </div>
            </div>
          </div>}
          {expiringRewardPoints == 0 && 
            <>
              <p className='text-sm font-fig-extrabold text-slate-600' style={{lineHeight: "12px"}}>{rewardText}</p>
            </>
          } 
        </div>
      }
    </div>
  )
}

export default HPoints